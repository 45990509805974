<template>
  <main>
    <section class="bingo-title t-center">
      <h3>Gossip Verwaltung</h3>
    </section>
    <nav>
      <div class="grid-12">
        <div class="col-sp-6 row-sp-12">
          <router-link class="button bgcolor-second color-white" to="dashboard">Zurück</router-link>
        </div>
        <div class="col-sp-6 row-sp-12 t-right" @click="createModal = true;">
          <button class="button bgcolor-second color-white">Gossip erstellen</button>
        </div>
      </div>
    </nav>
    <section class="all-events">
      <div class="headline">
        <h6>Alle Events</h6>
      </div>
      <allItems v-for="aitems in allItems" :allItems="aitems" :key="aitems.id" @delupdate="this.getallItems()"/>
    </section>
    <transition name="modal" class="bingoModal">
      <div class="vueModal" v-if="createModal">
        <div class="vueModalOuter" @click="createModal = false;"/>
        <div class="vueModalInner bgcolor-second bottom">
          <div class="t-center pad-16">
            <h2 class="color-burlywood" style="font-size: 1.5em;">Gossip erstellen</h2>
          </div>
          <div class="pad-8 t-center br-8">
            <div class="pad-4"></div>
            <textarea placeholder="Was wird passieren?" rows="4" v-model="content"></textarea>
          </div>
          <div class="t-left">
            <div class="pad-16 t-center">
              <button class="button bgcolor-black color-white br-8" @click="create();" v-if="content.length > 0">Erstellen</button>
              <div class="pad-4"></div>
              <button class="button bgcolor-burlywood color-black br-8" @click="createModal = false;">Schließen</button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </main>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import allItems from "@/components/edit/all";

export default {
  name: "Edit",
  title: "Edit",
  components: {
    'allItems': allItems
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      createModal: false,
      allItems: '',
      content: '',
    }
  },
  mounted() {
    this.config.loading = false
    this.getallItems()
  },
  methods: {
    create(){
      const _this = this
      const fD = new FormData()
      fD.append('content', this.content)
      this.axios.post(this.config.projectURL + 'edit/create', fD)
          .then((response) => {
            if(response.status === 250){
              _this.content = ''
              _this.createModal = false
              this.getallItems()
            }else{

            }
          })
    },
    getallItems(){
      const _this = this
      this.axios.post(this.config.projectURL + 'edit/getallItems')
          .then((response) => {
            console.log(response.data, response.status)
            if(response.status === 250){
              _this.allItems = response.data
            }
          })
    },
  }
}
</script>

<style>
  nav{
    padding: 16px;
  }
  .button-back{
    margin: 20px 0  0 20px;
  }

  .gossip-bingo-play-{
    margin: 30px;
    border: 2px solid white;
    border-radius: 4px;
  }

  .bingo-box{
    width: 100%;
    aspect-ratio: 1/1;
    line-height: 100px;
  }

  .bingo-1, .bingo-3, .bingo-6, .bingo-8, .bingo-9, .bingo-11, .bingo-14, .bingo-16{
    background-color: var(--second-bg-color);
  }
  .bingo-box.status-1{
    background-color: #91ad02!important;
  }
  .bingoModal .vueModalInner{
    width: calc(100% - 32px);
    background-color: var(--second-bg-color)!important;
    max-width: 350px;
  }
  .bingoModal .vueModalInner p{
    margin-top: 4px;
    color: rgba(0,0,0,0.4);
    font-size: 0.9em;
  }
  .bingoModal textarea{
    width: 100%;
    max-width: calc(100% - 16px);
    padding: 8px;
    border-radius: 8px;
  }

</style>
<template>
  <main>
    <section class="bingo-title t-center">
      <h3>Gossip Bingo</h3>
      <h4>Ilmenaugarten Edition</h4>
    </section>
    <section class="bingo-play t-center">
      <div class="bingo-play-wrapper">
        <router-link to="bingo" >
          <div class="bingo-start-button">
            <img src="assets/images/play_arrow_FILL0_wght400_GRAD0_opsz48.svg" alt="Zum Bingo">
            <h6 class="color-white">Play</h6>
          </div>
        </router-link>
        <router-link to="profil" class="bingo-start-button">
          <img src="assets/images/person_FILL0_wght400_GRAD0_opsz48.svg" alt="Zum Bingo">
          <h6 class="color-white">Profil</h6>
        </router-link>
      </div>
    </section>
    <div class="content-500">
      <section class="tasks-request">
        <div class="headline">
          <h6>Offene Anfragen</h6>
          <p>Bitte überprüfe und bestätige offene Anfragen:</p>
        </div>
        <requestItems v-for="items in requestItems" :requestItems="items" :key="items.id" @update="getrequestItems();getsuccessItems();getopenItems();checkBingo();"/>
      </section>
      <section class="achieved-events">
        <div class="headline">
          <h6>Erreichte Events</h6>
          <p>Diese Dinge sind bereits passiert:</p>
        </div>
        <successItems v-for="sitems in successItems" :successItems="sitems" :key="sitems.id"/>
      </section>
      <section class="all-events">
        <div class="headline">
          <h6>Offene Möglichkeiten</h6>
          <p>Hier sind alle Events aufgelistet, welche noch offen sind:</p>
        </div>
        <openItems v-for="oitems in openItems" :openItems="oitems" :key="oitems.id" @openupdate="getrequestItems();getsuccessItems();getopenItems();checkBingo();"/>
      </section>
    </div>
    <div class="pad-40"></div>
  </main>
  <transition name="modal" class="bingoWinModal">
    <div class="vueModal" v-if="BingoWinModal">
      <div class="vueModalOuter"/>
      <div class="vueModalInner bottom">
        <div class="t-center pad-16">
          <h2 class="color-burlywood">Wir haben einen gewinner!</h2>
          <div class="pad-4"></div>
          <p><b class="f-bold">{{ Winndername }}</b> hat das Gossip Bingo gewonnen!</p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import requestItems from "@/components/dashboard/requests";
import successItems from "@/components/dashboard/success";
import openItems from "@/components/dashboard/open";

export default {
  name: "DashBoard",
  title: "Dashboard",
  components: {
    'requestItems': requestItems,
    'successItems': successItems,
    'openItems': openItems
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      requestItems: '',
      successItems: '',
      openItems: '',
      Winndername: '',
      BingoWinModal: false,
    }
  },
  mounted() {
    this.config.loading = false
    this.getrequestItems()
    this.getsuccessItems()
    this.getopenItems()
    this.checkBingo()
  },
  methods: {
    getrequestItems(){
      const _this = this
      this.axios.post(this.config.projectURL + 'dashboard/requestItems')
          .then((response) => {
            console.log(response.data, response.status)
            if(response.status === 250){
              _this.requestItems = response.data
            }
          })
    },
    getsuccessItems(){
      const _this = this
      this.axios.post(this.config.projectURL + 'dashboard/successItems')
          .then((response) => {
            console.log(response.data, response.status)
            if(response.status === 250){
              _this.successItems = response.data
            }
          })
    },
    getopenItems(){
      const _this = this
      this.axios.post(this.config.projectURL + 'dashboard/openItems')
          .then((response) => {
            console.log(response.data, response.status)
            if(response.status === 250){
              _this.openItems = response.data
            }
          })
    },
    checkBingo(){
      const _this = this
      this.axios.post(this.config.projectURL + 'dashboard/checkBingo')
          .then((response) => {
            if(response.status === 250){
              _this.BingoWinModal = true
              _this.Winndername = response.data
              console.log(response.data)
            }else{
              _this.BingoWinModal = false
            }
          })
    },
  }
}
</script>

<style>
.bingo-title{
  margin-top: 40px;
}

.bingo-title h3{
  font-size: 2.2em;
  font-weight: 700;
  color: burlywood;
}

.bingo-title h4{
  font-size: 1.0em;
  font-weight: 300;
}

.bingo-play{
  margin: 60px 30px;
}

.bingo-play-wrapper{
  display: flex;
  gap: 20px;
  justify-content: center;
}

.bingo-start-button{
  background-color: var(--second-bg-color);
  padding: 20px 50px;
  border-radius: 12px;
}

.bingo-start-button h6{
  font-size: 1.2em;
  font-weight: 700;
}

.bingo-start-button img{
  width: 100%;
  max-width: 30px;
}

.tasks-request{
  margin: 40px 30px 0;

}


.headline{
  padding-bottom: 20px;
}

.headline h6{
  font-size: 1.4em;
  font-weight: 700;
  padding-bottom: 12px;
  color: burlywood;
}

.headline p{
  font-size: 1.0em;
  font-weight: 400;
  line-height: 1.4;
}
.all-events, .achieved-events{
  margin: 60px 30px;
}
.bingoWinModal{
  position: fixed;
  z-index: 9;
}
.bingoWinModal .vueModalInner{
  background-color: var(--second-bg-color)!important;
  width: calc(100% - 32px);
  max-width: 350px;
}
.bingoWinModal .field.simple input{
  width: calc(100% - 32px);
  margin-top: 2px;
  border-radius: 8px;
  border: none;
}

</style>
<template>
  <main id="profil">
    <section id="profilheader">
      <img :src="'assets/images/users/' + user.avatar" class="bgcolor-redbeige image width-100 circular"/>
      <div class="profilheaderInfos">
        <h2>{{ user.firstname }}</h2>
        <h4 class="f-normal color-burlywood">{{ user.email }}</h4>
      </div>
    </section>
    <section id="helpcontainer" class="pad-16">
      <div class="bgcolor-second pad-16 br-8 t-center">
        <h4 class="color-white">Gibt es Probleme?</h4>
        <p class="color-burlywood">
          Tja, Pech gehabt!
        </p>
      </div>
    </section>
  </main>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "Haushalt",
  title: "Haushalt",
  components: {
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {

  }
}
</script>

<style>

main#profil{
  padding-bottom: 80px;
  padding-top: 0!important;
}
#profilheader{
  text-align: center;
  padding-top: 40px;
}
#profilheader img{
  border: 4px solid var(--second-bg-color);
  position: relative;
  z-index: 1;
}
#profilheader .profilheaderInfos{
  background-color: var(--second-bg-color);
  width: calc(100% - 32px);
  margin: 0 auto;
  border-radius: 8px;
  padding-top: 40px;
  position: relative;
  z-index: 0;
  margin-top: -40px;
  padding-bottom: 16px;
}
#helpcontainer p{
  margin-top: 8px;
  font-size: 0.9em;
}
</style>
<template>
  <article class="event">
    <div class="event-wrapper">
      <h6>{{ allItems.id }}</h6>
      <p>{{ allItems.content }}</p>
    </div>
    <div class="event-confirm" @click="eventid = allItems.id; this.delete();">
      <img class="bgcolor-red" src="assets/images/8679882_delete_bin_2_line_icon.svg" alt="Event löschen">
    </div>
  </article>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "allItems",
  props: ["allItems"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      eventid: ''
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
    delete(){
      const _this = this
      const fD = new FormData()
      fD.append('eventid', this.eventid)
      console.log(this.eventid)
      this.axios.post(this.config.projectURL + 'edit/delete', fD)
          .then((response) => {
            if(response.status === 250){
              this.$emit('delupdate')
            }else{
              console.log('Fail')
            }
          })
    },
  }
}
</script>

<style scoped>
.event{
  display: flex;
  align-items: center;
  gap: 12px;
}

.event-confirm{
  flex: 1;
}
.event-wrapper p{
  font-weight: 400;
  font-size: 1em;
  text-align: left;
  line-height: 1.4;
}
.event-confirm img{
  width: 100%;
  max-width: 30px;
  border-radius: 12px;
  padding: 8px 12px;
}
.event-wrapper{
  display: flex;
  margin: 20px 0;
  align-items: center;
  flex: 4;
}

.event-wrapper h6{
  font-size: 2.4em;
  font-weight: 700;
  margin-right: 20px;
}
</style>
<template>
  <article class="event">
    <div class="event-wrapper">
      <h6>{{ openItems.id }}</h6>
      <p>{{ openItems.content }}</p>
    </div>
    <div class="event-confirm" @click="eventid = openItems.id; openRequest();">
      <img src="assets/images/done_FILL0_wght400_GRAD0_opsz48.svg" alt="Event abgehakt">
    </div>
  </article>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "openItems",
  props: ["openItems"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      timestamp: this.openItems.timestamp,
      coverttime: '',
      eventid: ''
    }
  },
  mounted() {
    this.config.loading = false
    this.covertTime()
  },
  methods: {
    covertTime(){
      const _this = this
      let timestamp = this.timestamp;
      const timestampArr = timestamp.split(" ");
      let date = timestampArr[0];
      let time = timestampArr[1];
      const dateArr = date.split("-");
      const timeArr = time.split(":");
      _this.coverttime = dateArr[2]+'.'+dateArr[1]+'.'+dateArr[0]+' - '+timeArr[0]+':'+timeArr[1]+' Uhr';
    },
    openRequest(){
      const _this = this
      const fD = new FormData()
      fD.append('eventid', this.eventid)
      console.log(this.eventid)
      this.axios.post(this.config.projectURL + 'dashboard/openRequest', fD)
          .then((response) => {
            console.log('RS'+response.status)
            if(response.status === 250){
              console.log('Request')
              this.$emit('openupdate')
            }else{
              console.log('Fail')
            }
          })
    },
  }
}
</script>

<style scoped>
.event{
  display: flex;
  align-items: center;
  gap: 12px;
}

.event-confirm{
  flex: 1;
}
.event-wrapper p{
  font-weight: 400;
  font-size: 1em;
  text-align: left;
  line-height: 1.4;
}
.event-confirm img{
  width: 100%;
  max-width: 30px;
  background-color: #4AB065;
  border-radius: 12px;
  padding: 8px 12px;
}
.event-wrapper{
  display: flex;
  margin: 20px 0;
  align-items: center;
  flex: 4;
}

.event-wrapper h6{
  font-size: 2.4em;
  font-weight: 700;
  margin-right: 20px;
}
</style>
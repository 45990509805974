import * as Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from './router'
import title from '@/mixins/title'
import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'
import deLocale from "@/locales/de.json"
import enLocale from "@/locales/en.json"

const pinia = createPinia();
pinia.use(({ store }) => {
  store.$router = Vue.markRaw(router)
});

const i18n = new createI18n({
  locale: 'en', // set locale
  messages: {
    en: enLocale,
    de: deLocale
  }, // set locale messages
})

const vueProto = Vue.createApp(App).use(pinia).use(router).use(VueAxios, axios).use(i18n).mixin(title)

vueProto.config.warnHandler = () => null;
router.isReady().then(() => window.wurstsalat = vueProto.mount('#App'))

<template>
  <article class="achieved-wrapper">
    <img src="assets/images/green-done_FILL0_wght400_GRAD0_opsz48.svg">
    <div class="achieved-content-wrapper">
      <h6>{{ coverttime }}</h6>
      <p>{{ successItems.content }}</p>
    </div>
  </article>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "successItems",
  props: ["successItems"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      timestamp: this.successItems.timestamp,
      coverttime: ''
    }
  },
  mounted() {
    this.config.loading = false
    this.covertTime()
  },
  methods: {
    covertTime(){
      const _this = this
      let timestamp = this.timestamp;
      const timestampArr = timestamp.split(" ");
      let date = timestampArr[0];
      let time = timestampArr[1];
      const dateArr = date.split("-");
      const timeArr = time.split(":");
      _this.coverttime = dateArr[2]+'.'+dateArr[1]+'.'+dateArr[0]+' - '+timeArr[0]+':'+timeArr[1]+' Uhr';
    }
  }
}
</script>

<style scoped>
.achieved-wrapper{
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}

.achieved-wrapper img{
  width: 100%;
  max-width: 50px;
  margin-right: 20px;
}

.achieved-content-wrapper h6{
  font-size: 0.9em;
  font-weight: 300;
  padding-top: 10px;
}

.achieved-wrapper p{
  font-weight: 400;
  font-size: 1em;
  text-align: left;
  line-height: 1.4;
}

.achieved-wrapper h6{
  font-size: 0.9em;
  font-weight: 300;
  padding-top: 10px;
}

</style>
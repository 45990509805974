<template>
  <div class="request-box">
    <div class="request-name-time">
      <h3>{{ requestItems.firstname }}</h3>
      <p>{{ coverttime }}</p>
    </div>
    <div class="request-box-content-wrapper">
      <h5>{{ requestItems.content }}</h5>
      <div style="display: block;width: 100%;text-align: center;">
        <div class="accept" @click="requestid = requestItems.id; accept();" style="display: inline;width: 50%;">
          <img src="assets/images/done_FILL0_wght400_GRAD0_opsz48.svg" alt="Anfragen akzeptiert">
        </div>
        <div class="rejected" @click="requestid = requestItems.id; rejected();" style="display: inline;width: 50%;">
          <img src="assets/images/close_FILL0_wght400_GRAD0_opsz48.svg" alt="Anfragen abgelehnt">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "requestItems",
  props: ["requestItems"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      timestamp: this.requestItems.timestamp,
      coverttime: '',
      requestid: ''
    }
  },
  mounted() {
    this.config.loading = false
    this.covertTime()
  },
  methods: {
    covertTime(){
      const _this = this
      let timestamp = this.timestamp;
      const timestampArr = timestamp.split(" ");
      let date = timestampArr[0];
      let time = timestampArr[1];
      const dateArr = date.split("-");
      const timeArr = time.split(":");
      _this.coverttime = dateArr[2]+'.'+dateArr[1]+'.'+dateArr[0]+' - '+timeArr[0]+':'+timeArr[1]+' Uhr';
    },
    accept(){
      const _this = this
      const fD = new FormData()
      fD.append('requestid', this.requestid)
      console.log(this.requestid)
      this.axios.post(this.config.projectURL + 'dashboard/accept', fD)
          .then((response) => {
            if(response.status === 250){
              this.$emit('update')
            }else{

            }
          })
    },
    rejected(){
      const _this = this
      const fD = new FormData()
      fD.append('requestid', this.requestid)
      console.log(this.requestid)
      this.axios.post(this.config.projectURL + 'dashboard/rejected', fD)
          .then((response) => {
            if(response.status === 250){
              this.$emit('update')
            }else{

            }
          })
    },
  }
}
</script>

<style scoped>
.request-box{
  border-radius: 12px;
  padding: 12px 28px;
  margin: 30px 0;
  background-color: var(--second-bg-color)
}

.request-box-content-wrapper{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.request-name-time{
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

.request-name-time h3{
  font-size: 1.3em;
  font-weight: 700;
  padding-top: 10px;
}

.request-name-time p{
  font-size: 0.9em;
  font-weight: 300;
  padding-top: 10px;
}

.request-box h5{
  font-size: 1em;
  font-weight: 400;
  padding: 12px 0 16px;
}

.accept, .rejected{
  padding: 10px;
  border-radius: 180px;
}

.accept img{
  width: 100%;
  max-width: 30px;
  background-color: #4AB065;
  border-radius: 12px;
  padding: 6px 30px;
}

.rejected img{
  width: 100%;
  max-width: 30px;
  background-color: #E94C56;
  border-radius: 12px;
  padding: 6px 30px;
}
</style>
<template>
  <footer>

  </footer>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    year(){
      return (new Date()).getFullYear()
    }
  }
}
</script>

<style scoped>
</style>
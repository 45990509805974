<template>
  <main>
    <section class="bingo-title t-center">
      <h3>Gossip Bingo</h3>
    </section>
    <nav>
      <div>
        <router-link class="button bgcolor-second color-white" to="dashboard">Zurück</router-link>
      </div>
    </nav>
    <section class="gossip-bingo-play-">
      <div class="grid-12">
        <div @click="fieldId = 1; bingoItemid = this.bingoItems[0]['F1_id']; bingoItemcontent = this.bingoItems[0]['F1_content']; bingoModal = true;" :class="'col-sp-3 row-sp-12 bingo-box bingo-1 t-center status-'+this.bingoItems[0]['F1_status']">
          <h1>{{ this.bingoItems[0]['F1_id'] }}</h1>
        </div>
        <div @click="fieldId = 2; bingoItemid = this.bingoItems[0]['F2_id']; bingoItemcontent = this.bingoItems[0]['F2_content']; bingoModal = true;" :class="'col-sp-3 row-sp-12 bingo-box bingo-2 t-center status-'+this.bingoItems[0]['F2_status']">
          <h1>{{ this.bingoItems[0]['F2_id'] }}</h1>
        </div>
        <div @click="fieldId = 3; bingoItemid = this.bingoItems[0]['F3_id']; bingoItemcontent = this.bingoItems[0]['F3_content']; bingoModal = true;" :class="'col-sp-3 row-sp-12 bingo-box bingo-3 t-center status-'+this.bingoItems[0]['F3_status']">
          <h1>{{ this.bingoItems[0]['F3_id'] }}</h1>
        </div>
        <div @click="fieldId = 4; bingoItemid = this.bingoItems[0]['F4_id']; bingoItemcontent = this.bingoItems[0]['F4_content']; bingoModal = true;" :class="'col-sp-3 row-sp-12 bingo-box bingo-4 t-center status-'+this.bingoItems[0]['F4_status']">
          <h1>{{ this.bingoItems[0]['F4_id'] }}</h1>
        </div>

        <div @click="fieldId = 5; bingoItemid = this.bingoItems[0]['F5_id']; bingoItemcontent = this.bingoItems[0]['F5_content']; bingoModal = true;" :class="'col-sp-3 row-sp-12 bingo-box bingo-5 t-center status-'+this.bingoItems[0]['F5_status']">
          <h1>{{ this.bingoItems[0]['F5_id'] }}</h1>
        </div>
        <div @click="fieldId = 6; bingoItemid = this.bingoItems[0]['F6_id']; bingoItemcontent = this.bingoItems[0]['F6_content']; bingoModal = true;" :class="'col-sp-3 row-sp-12 bingo-box bingo-6 t-center status-'+this.bingoItems[0]['F6_status']">
          <h1>{{ this.bingoItems[0]['F6_id'] }}</h1>
        </div>
        <div @click="fieldId = 7; bingoItemid = this.bingoItems[0]['F7_id']; bingoItemcontent = this.bingoItems[0]['F7_content']; bingoModal = true;" :class="'col-sp-3 row-sp-12 bingo-box bingo-7 t-center status-'+this.bingoItems[0]['F7_status']">
          <h1>{{ this.bingoItems[0]['F7_id'] }}</h1>
        </div>
        <div @click="fieldId = 8; bingoItemid = this.bingoItems[0]['F8_id']; bingoItemcontent = this.bingoItems[0]['F8_content']; bingoModal = true;" :class="'col-sp-3 row-sp-12 bingo-box bingo-8 t-center status-'+this.bingoItems[0]['F8_status']">
          <h1>{{ this.bingoItems[0]['F8_id'] }}</h1>
        </div>

        <div @click="fieldId = 9; bingoItemid = this.bingoItems[0]['F9_id']; bingoItemcontent = this.bingoItems[0]['F9_content']; bingoModal = true;" :class="'col-sp-3 row-sp-12 bingo-box bingo-9 t-center status-'+this.bingoItems[0]['F9_status']">
          <h1>{{ this.bingoItems[0]['F9_id'] }}</h1>
        </div>
        <div @click="fieldId = 10; bingoItemid = this.bingoItems[0]['F10_id']; bingoItemcontent = this.bingoItems[0]['F10_content']; bingoModal = true;" :class="'col-sp-3 row-sp-12 bingo-box bingo-10 t-center status-'+this.bingoItems[0]['F10_status']">
          <h1>{{ this.bingoItems[0]['F10_id'] }}</h1>
        </div>
        <div @click="fieldId = 11; bingoItemid = this.bingoItems[0]['F11_id']; bingoItemcontent = this.bingoItems[0]['F11_content']; bingoModal = true;" :class="'col-sp-3 row-sp-12 bingo-box bingo-11 t-center status-'+this.bingoItems[0]['F11_status']">
          <h1>{{ this.bingoItems[0]['F11_id'] }}</h1>
        </div>
        <div @click="fieldId = 12; bingoItemid = this.bingoItems[0]['F12_id']; bingoItemcontent = this.bingoItems[0]['F12_content']; bingoModal = true;" :class="'col-sp-3 row-sp-12 bingo-box bingo-12 t-center status-'+this.bingoItems[0]['F12_status']">
          <h1>{{ this.bingoItems[0]['F12_id'] }}</h1>
        </div>

        <div @click="fieldId = 13; bingoItemid = this.bingoItems[0]['F13_id']; bingoItemcontent = this.bingoItems[0]['F13_content']; bingoModal = true;" :class="'col-sp-3 row-sp-12 bingo-box bingo-13 t-center status-'+this.bingoItems[0]['F13_status']">
          <h1>{{ this.bingoItems[0]['F13_id'] }}</h1>
        </div>
        <div @click="fieldId = 14; bingoItemid = this.bingoItems[0]['F14_id']; bingoItemcontent = this.bingoItems[0]['F14_content']; bingoModal = true;" :class="'col-sp-3 row-sp-12 bingo-box bingo-14 t-center status-'+this.bingoItems[0]['F14_status']">
          <h1>{{ this.bingoItems[0]['F14_id'] }}</h1>
        </div>
        <div @click="fieldId = 15; bingoItemid = this.bingoItems[0]['F15_id']; bingoItemcontent = this.bingoItems[0]['F15_content']; bingoModal = true;" :class="'col-sp-3 row-sp-12 bingo-box bingo-15 t-center status-'+this.bingoItems[0]['F15_status']">
          <h1>{{ this.bingoItems[0]['F15_id'] }}</h1>
        </div>
        <div @click="fieldId = 16; bingoItemid = this.bingoItems[0]['F16_id']; bingoItemcontent = this.bingoItems[0]['F16_content']; bingoModal = true;" :class="'col-sp-3 row-sp-12 bingo-box bingo-12 t-center status-'+this.bingoItems[0]['F16_status']">
          <h1>{{ this.bingoItems[0]['F16_id'] }}</h1>
        </div>

      </div>
    </section>
    <transition name="modal" class="bingoModal">
      <div class="vueModal" v-if="bingoModal">
        <div class="vueModalOuter" @click="bingoModal = false;"/>
        <div class="vueModalInner bgcolor-second bottom" v-if="bingoItemid != null">
          <div class="t-center pad-16">
            <h2 class="color-burlywood" style="font-size: 2em;">#{{ bingoItemid }}</h2>
          </div>
          <div class="pad-8 t-center br-8">
              <div class="pad-4"></div>
              <p class="color-white" style="font-size: 1.2em;">
                {{ bingoItemcontent }}
              </p>
          </div>
          <div class="t-left">
            <div class="pad-16 t-center">
              <button class="button bgcolor-burlywood color-black br-8" @click="bingoModal = false;">Schließen</button>
            </div>
          </div>
        </div>
        <div class="vueModalInner bgcolor-second bottom" v-else>
          <div class="t-center pad-16">
            <h2 class="color-burlywood" style="font-size: 1.5em;">Bingo Event auswählen</h2>
          </div>
          <div class="pad-8 t-center br-8">
            <div class="pad-4"></div>
            <select class="pad-8" style="max-width: 100%;" v-model="selectid">
              <option :value="items.id" v-for="items in openSelectionItems" :openSelectionItems="items" :key="items.id" >#{{ items.id }} - {{ items.content }}</option>
            </select>
          </div>
          <div class="t-left">
            <div class="pad-16 t-center">
              <button class="button bgcolor-black color-white br-8" @click="setfield();" v-if="selectid != null">Bestätigen</button>
              <div class="pad-4"></div>
              <button class="button bgcolor-burlywood color-black br-8" @click="bingoModal = false;">Schließen</button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </main>
  <transition name="modal" class="bingoWinModal">
    <div class="vueModal" v-if="BingoWinModal">
      <div class="vueModalOuter"/>
      <div class="vueModalInner bottom">
        <div class="t-center pad-16">
          <h2 class="color-burlywood">Wir haben einen gewinner!</h2>
          <div class="pad-4"></div>
          <p><b class="f-bold">{{ Winndername }}</b> hat das Gossip Bingo gewonnen!</p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";


export default {
  name: "Bingo",
  title: "Bingo",
  components: {
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      selectid: null,
      bingoItems: '',
      bingoModal: false,
      bingoItemid: '',
      bingoItemcontent: '',
      fieldId: '',
      openSelectionItems: '',
      Winndername: '',
      BingoWinModal: false,
    }
  },
  mounted() {
    this.config.loading = false
    this.getmyField()
    this.getselectionItems()
    this.checkBingo()
  },
  methods: {
    getmyField(){
      const _this = this
      this.axios.post(this.config.projectURL + 'bingo/getmyField')
          .then((response) => {
            console.log(response.data, response.status)
            if(response.status === 250){
              _this.bingoItems = response.data
            }
          })
    },
    getselectionItems(){
      const _this = this
      this.axios.post(this.config.projectURL + 'bingo/getselectionItems')
          .then((response) => {
            console.log(response.data, response.status)
            if(response.status === 250){
              _this.openSelectionItems = response.data
            }
          })
    },
    setfield(){
      const _this = this
      const fD = new FormData()
      fD.append('eventid', this.selectid)
      fD.append('fieldid', this.fieldId)
      this.axios.post(this.config.projectURL + 'bingo/setfield', fD)
          .then((response) => {
            console.log(response.status)
            if(response.status === 250){
              this.getmyField()
              this.getselectionItems()
              _this.selectid = null
              _this.fieldId = ''
              _this.bingoModal = false
            }else{

            }
          })
    },
    checkBingo(){
      const _this = this
      this.axios.post(this.config.projectURL + 'dashboard/checkBingo')
          .then((response) => {
            if(response.status === 250){
              _this.BingoWinModal = true
              _this.Winndername = response.data
              console.log(response.data)
            }else{
              _this.BingoWinModal = false
            }
          })
    },
  }
}
</script>

<style>
  nav{
    padding: 16px;
  }
  .button-back{
    margin: 20px 0  0 20px;
  }

  .gossip-bingo-play-{
    margin: 30px;
    border: 2px solid white;
    border-radius: 4px;
  }

  .bingo-box{
    width: 100%;
    aspect-ratio: 1/1;
    line-height: 100px;
  }

  .bingo-1, .bingo-3, .bingo-6, .bingo-8, .bingo-9, .bingo-11, .bingo-14, .bingo-16{
    background-color: var(--second-bg-color);
  }
  .bingo-box.status-1{
    background-color: #91ad02!important;
  }
  .bingoModal .vueModalInner{
    width: calc(100% - 32px);
    background-color: var(--second-bg-color)!important;
    max-width: 350px;
  }
  .bingoModal .vueModalInner p{
    margin-top: 4px;
    color: rgba(0,0,0,0.4);
    font-size: 0.9em;
  }
  .bingoWinModal{
    position: fixed;
    z-index: 9;
  }
  .bingoWinModal .vueModalInner{
    background-color: var(--second-bg-color)!important;
    width: calc(100% - 32px);
    max-width: 350px;
  }
  .bingoWinModal .field.simple input{
    width: calc(100% - 32px);
    margin-top: 2px;
    border-radius: 8px;
    border: none;
  }
</style>